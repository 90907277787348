<template>
    <div>
        <div class="padding">
            <el-page-header :title="$t('返回')" @back="goBack" :content="$t('练习统计')">
            </el-page-header>
        </div>
        <el-card class="margin-t" style="max-width: 1000px; margin:0 auto;">

            <div class="padding margin-t">
                <div class="bold center lt">{{ $t('今日练习数据') }}</div>
                <div class="flex margin-lt">
                    <div class="flex1">
                        <div class="bold center">{{ $t('题目') }}</div>
                        <div class="center bold margin-ts">{{ datas[0] }}{{ $t('题') }}</div>
                    </div>
                    <div class="flex1">
                        <div class="bold center">{{ $t('单词') }}</div>
                        <div class="center bold margin-ts">{{ datas[1] }}个</div>
                    </div>
                    <div class="flex1">
                        <div class="bold center">{{ $t('时长') }}</div>
                        <div class="center bold margin-ts">{{ datas[2] }}小时</div>
                    </div>
                </div>
                <div class="flex flex-v-center">
                    <div class="bold margin-r" style="writing-mode: vertical-lr;letter-spacing: 10px;">{{ $t('我的练习周报')
                        }}</div>
                    <div class="flex1" id="chart" style="width:100%;height: 300px;margin: 20px 0"></div>
                </div>

                <div class="flex flex-h-center margin padding" style="position: relative;">
                    <el-button round type="primary" @click="toPlan()">{{$t('练习今日计划')}}</el-button>
                    <img v-if="tickOff" src="/web/image/tick_off.png"
                        style="position: absolute;z-index: 1000;top:-20px;left:calc(50% + 100px); height: 100px;width: 100px;" />
                </div>
                <div class="bold center lt">{{ $t('总练习数据') }}</div>
                <div class="flex margin-lt">
                    <div class="flex1">
                        <div class="bold center">{{ $t('题目') }}</div>
                        <div class="center bold margin-ts">{{ $num(datas[3]) }}{{$t('题')}}</div>
                    </div>
                    <div class="flex1">
                        <div class="bold center">{{ $t('单词') }}</div>
                        <div class="center bold margin-ts">{{ $num(datas[4]) }}{{$t('个')}}</div>
                    </div>
                    <div class="flex1">
                        <div class="bold center">{{ $t('时长') }}</div>
                        <div class="center bold margin-ts">{{ $num(datas[5]) }}{{$t('小时')}}</div>
                    </div>
                </div>

                <div style="height: 50px;"></div>

                <el-divider>
                    <div class="bold center lt">{{ $t('正确率统计') }}</div>
                </el-divider>

                <div class="padding">
                    <el-tabs v-model="activeTab" @tab-click="tabChange">
                        <el-tab-pane :label="key" :name="key" v-for="(key, index) in Object.keys(tableData)"
                            :key="index">
                        </el-tab-pane>
                    </el-tabs>
                    <el-table :data="tableData[activeTab] || []" style="width: 100%">
                        <el-table-column prop="tag" :label="$t('题型')"></el-table-column>
                        <el-table-column prop="r1" :label="$t('我的正确率 / 目标')">
                            <template slot-scope="scope">
                                {{ scope.row.r1 }}% / {{ scope.row.r2 }}%
                            </template>
                        </el-table-column>
                        <el-table-column prop="rate" label="">
                            <template slot-scope="scope">
                                <div class="slider out">
                                    <div class="slider inner1" :style="{ width: scope.row.r1 + '%' }"></div>
                                    <div class="slider inner2" :style="{ width: scope.row.r2 + '%' }"></div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="st padding-s">{{$t('目标正确率：根据同学目标成绩计算生成')}}</div>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { LineChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    DatasetComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    TransformComponent
} from 'echarts/components';
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';

// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    MarkLineComponent,
    LineChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    LegendComponent,
    ToolboxComponent
]);
export default {
    data() {
        return {
            tableData: {},
            datas: [],
            chart: null,
            chartOpiton: {
                title: {
                    text: ''
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: [this.$t('单词数'), this.$t('题目数')]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: [this.$t('周一'), this.$t('周二'), this.$t('周三'), this.$t('周四'), this.$t('周五'), this.$t('周六'), this.$t('周日')]
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: this.$t('单词数'),
                        type: 'line',
                        stack: 'Total',
                        data: [120, 132, 101, 134, 90, 230, 210]
                    },
                    {
                        name: this.$t('题目数'),
                        type: 'line',
                        stack: 'Total',
                        data: [220, 182, 191, 234, 290, 330, 310]
                    },
                ]
            },
            activeTab: 'Speaking',
            tickOff: false,
        };
    },

    created() {
        this.query()
        // this.setTableData()
        this.getAccuracies()
        this.query_plans()
    },

    mounted() {
        this.chart = echarts.init(document.getElementById('chart'));
        this.query_chart();

    },
    // watch: {
    //     sorts(val) {
    //         if (val) this.setTableData()
    //     }
    // },

    computed: {
        records: function () {
            return this.$store.state.records
        },

        sorts: function () {
            return this.$store.getters.getSorts(0)
        }
    },

    methods: {
        query_plans() {
            this.$http.post("/api/today/plans").then((data) => {
                if (data) {
                    let tickOff = true
                    const index = data.findIndex(item => item.finish_quantity < item.quantity)
                    if (index !== -1) {
                        tickOff = false
                    }
                    this.tickOff = tickOff
                }
            })
        },


        getAccuracies() {
            this.$http.post("/api/source/accuracies").then((data) => {
                console.log(data)
                this.tableData = data
            })
        },

        setTableData() {
            this.sorts.forEach(sort => {
                this.tableData[sort.title] = sort.types.map((type) => {
                    return { tag: type.tag, r1: 50, r2: 80 }
                })
            })
            console.log(this.tableData)
        },
        tabChange() {
        },
        exportPic() {
            this.$message.warning("功能开发中，敬请期待...")
        },

        goBack() {
            this.$router.back();
        },

        toPlan() {
            this.$router.replace('/main/plan?from=mine')
        },
        query() {
            this.$http.post("/api/user/statistics").then((datas) => {
                this.datas = datas
            })
        },
        query_chart() {
            this.$http.post("/api/user/chart").then((datas) => {
                this.chartOpiton.series[0].data = datas[0]
                this.chartOpiton.series[1].data = datas[1]
                this.chart.setOption(this.chartOpiton);
            })
        },
    },
};
</script>
<style scoped>
.document>>>.el-card__header {
    padding: 10px 20px;
}


.document>>>.el-divider__text {
    color: #aaa;
}

.slider {
    background-color: #d9d9d9;
    width: 100%;
    border-radius: 10px;
    height: 15px;
}

.slider.out {
    position: relative;
}

.slider.inner1 {
    position: absolute;
    background-color: #fead00;
}


.slider.inner2 {
    position: absolute;
    background-color: rgba(254, 173, 0, 0.5);
}
</style>
